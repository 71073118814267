.mobile-whoweare {
  padding-right: 15px;

  -webkit-overflow-scrolling: auto !important;
  
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff3e;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9c1212b0;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #9c1212;
  }
}

@media(max-width: 1112px){
  .ipad-whoweare{
    margin-left: 45px !important;
    padding-left: 20px;
  }

  .mobile-whoweare{
    padding-right: 5px !important;
  }

  .ipad-header{
    width: 80% !important;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 10px;
  }

  .ipad-subheader{
    width: 75% !important;
  }
}

@media(max-width: 992px){
  .ipad-whoweare{
    margin-left: -5px !important;
    padding-left: 20px;
  }

  .mobile-whoweare{
    padding-right: 5px !important;
  }

  .ipad-header{
    width: 80% !important;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 70px;
  }

  .ipad-subheader{
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  .mobile-whoweare {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .ipad-header{
    width: 80% !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
  }

  .ipad-subheader{
    width: 90% !important;
  }
}


