@media (min-width: 768px) and (min-height: 1024px),
  (max-width: 820px) and (max-height: 1061px) {
  #project_header {
    margin-top: 80px;
  }
}

#project_bottom {
  .carousel .control-dots .dot {
    width: 15px;
    height: 15px;
  }

  .carousel-slider {
    overflow: inherit;
  }
}
