#production {
    .frame-video {
        // border: solid red 1px;
        width: 800px;
        height: 500px;
        // z-index: 10;
        background-image: url("../../../public/img/production/video-frame.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .video-content-container {
        width: 720px;
        height: 403px;
        background-color: black;
        overflow: hidden;
        border: black solid 5px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }

    .video-play {
        position: absolute;
        z-index: 30;
        top: 5%;
        left: 3%;
        background-color: black;
    }

    .video-pause {
        display: none;
    }

    .playButton {
        max-width: 70px;
    }

    .video-modal-wrapper {
        padding: 56.25% 0 0 0;
        position: relative;

        .video-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .carousel {
        .__carousel-item {
            display: none;

            &.active {
                display: block;
            }
        }

        // .__carousel-nav {
        //     .__prev,
        //     .__next {
        //         position: absolute;
        //         top: 81%;
        //         width: 50px;
        //         height: 50px;
        //         display: flex;
        //         cursor: pointer;
        //         transition: all 0.25s ease;
        //         span {
        //             width: 100%;
        //             height: 100%;
        //         }
        //     }
        //     .__prev {
        //         left: 43%;
        //         &:hover {
        //             margin-left: -5px;
        //         }
        //         span {
        //             background: url("../../img/left.png") center/contain
        //                 no-repeat;
        //         }
        //     }
        //     .__next {
        //         right: 43%;
        //         &:hover {
        //             margin-right: -5px;
        //         }
        //         span {
        //             background: url("../../img/right.png") center/contain
        //                 no-repeat;
        //         }
        //     }
        // }

        .__carousel-nav {

            .__prev,
            .__next {
                position: absolute;
                top: 35%;
                width: 50px;
                height: 50px;
                display: flex;
                cursor: pointer;
                transition: all 0.25s ease;

                span {
                    width: 100%;
                    height: 100%;
                }
            }

            .__prev {
                left: -10%;

                &:hover {
                    margin-left: -5px;
                }

                span {
                    background: url("../../img/left.png") center/contain no-repeat;
                }
            }

            .__next {
                right: -10%;

                &:hover {
                    margin-right: -5px;
                }

                span {
                    background: url("../../img/right.png") center/contain no-repeat;
                }
            }
        }

        .__carousel-content {
            opacity: 0;
        }

        &:hover {
            .__carousel-content {
                &.show {
                    opacity: 1;
                    transition: opacity 1s ease-out;
                }
            }
        }
    }

    .__carousel-indicator {
        position: absolute;
        display: flex;
        width: 100%;
        bottom: 12%;
        justify-content: center;
        align-items: center;
        gap: 5px;

        li {
            border: 2px solid #9c1212;
            background-color: #9c1212;
            border-radius: 50%;
            height: 8px;
            width: 8px;

            &.active {
                height: 12px;
                width: 12px;
            }
        }
    }
}

.gallery-carousel {
    .carousel {
        .control-dots .dot {
            margin: 0 4px;
        }
        .control-prev.control-arrow:before {
            border-right: 12px solid #fff;
            filter: drop-shadow(1px 1px 2px black);
        }
        .control-next.control-arrow:before {
            border-left: 12px solid #fff;
            filter: drop-shadow(1px 1px 2px black);
        }
    }

    .control-arrow,
    .carousel.carousel-slider .control-arrow {
        opacity: 1;
    }
}


@media screen and (min-width: 1430px) and (min-height: 700px) and (max-height: 900px) {
    .ipad-pro {
        max-width: 950px;
    }

    .video-content-container {
        width: 890px !important;
        height: 500px !important;
    }

    .video-play {
        top: 4.6% !important;
    }
}

@media screen and (max-width: 1370px) and (pointer:coarse) {
    #production {
        .ipad-pro {
            max-width: 1320px;
        }

        .video-content-container {
            width: 985px;
            height: 545px;
        }

        .playButton {
            max-width: 70px;
        }

        .carousel {
            .__carousel-nav {

                .__prev,
                .__next {
                    top: 88%;
                }

                .__prev {
                    left: 43%;
                }

                .__next {
                    right: 43%;
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .ipad-pro {
        max-width: 1020px;
    }

    #production {
        .video-content-container {
            width: 955px;
            height: 530px;
        }

        .playButton {
            max-width: 85px;
        }

        .carousel {
            .__carousel-nav {

                .__prev,
                .__next {
                    top: 85%;
                }

                .__prev {
                    left: 43%;
                }

                .__next {
                    right: 43%;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .ipad-pro {
        max-width: 1020px;
    }

    #production {
        .video-content-container {
            width: 910px;
            height: 505px;
        }

        .playButton {
            max-width: 85px;
        }

        .carousel {
            .__carousel-nav {

                .__prev,
                .__next {
                    top: 85%;
                }
            }
        }
    }
}

@media screen and (max-width: 940px) {
    #production {

        .video-content-container {
            width: 795px;
            height: 450px;
            border: 5px;
        }
    }
}

@media screen and (max-width: 900px) {
    #production {
        .playButton {
            max-width: 70px;
        }

        .video-content-container {
            width: 710px;
            height: 400px;
        }

        .carousel {
            .__carousel-nav {

                .__prev,
                .__next {
                    top: 85%;
                }

                .__prev {
                    left: 42%;

                    &:hover {
                        margin-left: 0;
                    }
                }

                .__next {
                    right: 42%;

                    &:hover {
                        margin-right: 0;
                    }
                }
            }

            .__carousel-indicator {
                bottom: 16%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #production {
        .playButton {
            max-width: 50px;
        }

        .video-content-container {
            width: 660px;
            height: 370px;
        }

        .carousel {
            .__carousel-nav {
                .__prev {
                    left: 42% !important;
                }

                .__next {
                    right: 42% !important;
                }
            }

            .__carousel-indicator {
                li {
                    height: 7px;
                    width: 7px;

                    &.active {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    #production {
        .playButton {
            max-width: 40px;
        }

        .video-play {
            top: 8%;
        }

        .video-content-container {
            width: 365px;
            height: 205px;
        }

        .carousel {
            .__carousel-nav {
                .__prev {
                    left: 42% !important;
                }

                .__next {
                    right: 42% !important;
                }
            }

            .__carousel-indicator {
                li {
                    height: 7px;
                    width: 7px;

                    &.active {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 460px) {
    #production {

        .video-content-container {
            width: 365px;
            height: 205px;
            border: 5px;
        }
    }
}



@media screen and (max-width: 420px) {
    #production {

        .video-content-container {
            width: 350px;
            height: 195px;
            border: 5px;
        }

        .carousel {
            .__carousel-nav {
                .__prev {
                    left: 42% !important;
                }

                .__next {
                    right: 42% !important;
                }
            }

            .__carousel-indicator {
                li {
                    height: 7px;
                    width: 7px;

                    &.active {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    #production {

        .video-content-container {
            width: 330px;
            height: 185px;
        }
    }
}