.desktop-logo,
.ipad-logo,
.mobile-logo {
  display: none;
}

@media (max-width: 767px) {
  .desktop-logo {
    display: none;
  }
  .ipad-logo {
    display: none;
  }
  .mobile-logo {
    display: block;
  }
  #project_bottom_mobile .carousel-slider{
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .desktop-logo {
    display: none;
  }
  .ipad-logo {
    display: block;
  }
  .mobile-logo {
    display: none;
  }

  #project_bottom_ipad .carousel{
    height: 150px;
  }
}

@media (min-width: 992px) {
  .desktop-logo {
    display: block;
  }
  .ipad-logo {
    display: none;
  }
  .mobile-logo {
    display: none;
  }

  #project_bottom_desktop .carousel{
    height: 150px;
  }
}
