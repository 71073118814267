@import './variables';

.menu {
    gap: 0.75rem;
}
.menuItem {
    transition: all 0.25s;
    &:hover, &:focus {
        transform: scale(1.05) translateY(-2px);
    }
    a {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        padding: .25rem;
        display: inline-block;

    }
    &:last-child {
        // width: 150px;
        // border: 1px solid #ffffff;
        // border-radius: 1rem;
        // &:hover {
        //     background-color: #ffffff;
        //     a {
        //         color: $primary;
        //     }
        // }
        a {
            width: 100%;
            text-align: center;
        }
    }
}

@media (max-width: 1080px){
    .menuItem {
        a {
            font-size: 13px;
            padding: .18rem;
        }
    }
}
