#production .frame-video {
  width: 800px;
  height: 500px;
  /* background-image: url("../../../public/img/production/video-frame.png"); */
  background-image: url("../img/video-frame.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#production .video-content-container {
  width: 720px;
  height: 403px;
  background-color: black;
  overflow: hidden;
  border: black solid 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
#production .video-play {
  position: absolute;
  z-index: 30;
  top: 5%;
  left: 3%;
  background-color: black;
}
#production .video-pause {
  display: none;
}
#production .playButton {
  max-width: 70px;
}
#production .video-modal-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}
#production .video-modal-wrapper .video-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#production .carousel .__carousel-item {
  display: none;
}
#production .carousel .__carousel-item.active {
  display: block;
}
#production .carousel .__carousel-nav .__prev,
#production .carousel .__carousel-nav .__next {
  position: absolute;
  top: 35%;
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  transition: all 0.25s ease;
}
#production .carousel .__carousel-nav .__prev span,
#production .carousel .__carousel-nav .__next span {
  width: 100%;
  height: 100%;
}
#production .carousel .__carousel-nav .__prev {
  left: -10%;
}
#production .carousel .__carousel-nav .__prev:hover {
  margin-left: -5px;
}
#production .carousel .__carousel-nav .__prev span {
  background: url("../img/left.png") center/contain no-repeat;
}
#production .carousel .__carousel-nav .__next {
  right: -10%;
}
#production .carousel .__carousel-nav .__next:hover {
  margin-right: -5px;
}
#production .carousel .__carousel-nav .__next span {
  background: url("../img/right.png") center/contain no-repeat;
}
#production .carousel .__carousel-content {
  opacity: 0;
}
#production .carousel:hover .__carousel-content.show {
  opacity: 1;
  transition: opacity 1s ease-out;
}
#production .__carousel-indicator {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 12%;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
#production .__carousel-indicator li {
  border: 2px solid #9c1212;
  background-color: #9c1212;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
#production .__carousel-indicator li.active {
  height: 12px;
  width: 12px;
}

.gallery-carousel .carousel .control-dots .dot {
  margin: 0 4px;
}
.gallery-carousel .carousel .control-prev.control-arrow:before {
  border-right: 12px solid #fff;
  filter: drop-shadow(1px 1px 2px black);
}
.gallery-carousel .carousel .control-next.control-arrow:before {
  border-left: 12px solid #fff;
  filter: drop-shadow(1px 1px 2px black);
}
.gallery-carousel .control-arrow,
.gallery-carousel .carousel.carousel-slider .control-arrow {
  opacity: 1;
}

@media screen and (min-width: 1430px) and (min-height: 700px) and (max-height: 900px) {
  .ipad-pro {
    max-width: 950px;
  }
  .video-content-container {
    width: 890px !important;
    height: 500px !important;
  }
  .video-play {
    top: 4.6% !important;
  }
}
@media screen and (max-width: 1370px) and (pointer: coarse) {
  #production .ipad-pro {
    max-width: 1320px;
  }
  #production .video-content-container {
    width: 985px;
    height: 545px;
  }
  #production .playButton {
    max-width: 70px;
  }
  #production .carousel .__carousel-nav .__prev,
  #production .carousel .__carousel-nav .__next {
    top: 88%;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 43%;
  }
  #production .carousel .__carousel-nav .__next {
    right: 43%;
  }
}
@media screen and (max-width: 1080px) {
  .ipad-pro {
    max-width: 1020px;
  }
  #production .video-content-container {
    width: 955px;
    height: 530px;
  }
  #production .playButton {
    max-width: 85px;
  }
  #production .carousel .__carousel-nav .__prev,
  #production .carousel .__carousel-nav .__next {
    top: 85%;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 43%;
  }
  #production .carousel .__carousel-nav .__next {
    right: 43%;
  }
}
@media screen and (max-width: 1050px) {
  .ipad-pro {
    max-width: 1020px;
  }
  #production .video-content-container {
    width: 910px;
    height: 505px;
  }
  #production .playButton {
    max-width: 85px;
  }
  #production .carousel .__carousel-nav .__prev,
  #production .carousel .__carousel-nav .__next {
    top: 85%;
  }
}
@media screen and (max-width: 940px) {
  #production .video-content-container {
    width: 795px;
    height: 450px;
    border: 5px;
  }
}
@media screen and (max-width: 900px) {
  #production .playButton {
    max-width: 70px;
  }
  #production .video-content-container {
    width: 710px;
    height: 400px;
  }
  #production .carousel .__carousel-nav .__prev,
  #production .carousel .__carousel-nav .__next {
    top: 85%;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 42%;
  }
  #production .carousel .__carousel-nav .__prev:hover {
    margin-left: 0;
  }
  #production .carousel .__carousel-nav .__next {
    right: 42%;
  }
  #production .carousel .__carousel-nav .__next:hover {
    margin-right: 0;
  }
  #production .carousel .__carousel-indicator {
    bottom: 16%;
  }
}
@media screen and (max-width: 768px) {
  #production .playButton {
    max-width: 50px;
  }
  #production .video-content-container {
    width: 660px;
    height: 370px;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 42% !important;
  }
  #production .carousel .__carousel-nav .__next {
    right: 42% !important;
  }
  #production .carousel .__carousel-indicator li {
    height: 7px;
    width: 7px;
  }
  #production .carousel .__carousel-indicator li.active {
    height: 10px;
    width: 10px;
  }
}
@media screen and (max-width: 540px) {
  #production .playButton {
    max-width: 40px;
  }
  #production .video-play {
    top: 8%;
  }
  #production .video-content-container {
    width: 365px;
    height: 205px;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 42% !important;
  }
  #production .carousel .__carousel-nav .__next {
    right: 42% !important;
  }
  #production .carousel .__carousel-indicator li {
    height: 7px;
    width: 7px;
  }
  #production .carousel .__carousel-indicator li.active {
    height: 10px;
    width: 10px;
  }
}
@media screen and (max-width: 460px) {
  #production .video-content-container {
    width: 365px;
    height: 205px;
    border: 5px;
  }
}
@media screen and (max-width: 420px) {
  #production .video-content-container {
    width: 350px;
    height: 195px;
    border: 5px;
  }
  #production .carousel .__carousel-nav .__prev {
    left: 42% !important;
  }
  #production .carousel .__carousel-nav .__next {
    right: 42% !important;
  }
  #production .carousel .__carousel-indicator li {
    height: 7px;
    width: 7px;
  }
  #production .carousel .__carousel-indicator li.active {
    height: 10px;
    width: 10px;
  }
}
@media screen and (max-width: 400px) {
  #production .video-content-container {
    width: 330px;
    height: 185px;
  }
}/*# sourceMappingURL=carousel.css.map */